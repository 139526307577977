:root {
	--sidebar-width: 14em;

	--topbar-height: 4em;

	--margin-a: 2em;
	--margin-b: 1em;
	--margin-c: 0.75em;
	--margin-d: 0.5em;
	--margin-e: 0.25em;
	--margin-f: 0.125em;

	--border-radius: var(--margin-b);

	--alert-height: 0em;

	--background-color: white;
	--sidebar-color: #eee;
	--page-header-color: #f7f7f7;
	--shadow-color: #66666655;
	--text-color: black;
	--subtle-gray: #fafafa;
	--light-gray: #ddd;
	--medium-gray: #999;
	--dark-gray: #333;
	--accent-color: #3a405a;
	--button-color: var(--sidebar-color);
	--button-color-active: #ccc;

	@media (prefers-color-scheme: dark) {
		--background-color: #0f0f0f;
		--sidebar-color: #1d1d1d;
		--page-header-color: #181818;
		--shadow-color: #000000;
		--text-color: white;
		--subtle-gray: #333;
		--light-gray: #333;
		--medium-gray: #999;
		--dark-gray: #ccc;
		--accent-color: #e6cdba;
		--button-color-active: #4b4b4b;

		.arrow > svg,
		svg.arrow {
			filter: invert(1);
		}

		.poll .arrow--active {
			box-shadow: inset 0 1px 1px #777;
		}
	}

	position: absolute;
	inset: 0;
	overflow-x: hidden;
}

body {
	background-color: var(--sidebar-color);
	// background: linear-gradient(to bottom right, #bdc1d3, #dac5ba) fixed;
	// color: white;
	display: flex;
	flex-flow: column;
	min-height: 100%;
	overflow-x: hidden;
	position: relative;
	color: var(--text-color);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Inter";
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: transparent;
	scrollbar-color: auto;
}

// https://coolors.co/3a405a-f9dec9-99b2dd-e9afa3-685044

::selection {
	background-color: rgb(145, 148, 158);
}

a {
	text-decoration: none;
	color: inherit;
}

ul {
	list-style-type: none;
}

img.round {
	border-radius: 100vh;
	aspect-ratio: 1;
	object-fit: cover;
}

.clickable,
button,
nav a {
	cursor: pointer;
	display: block;
	border-radius: var(--margin-d);
	padding: var(--margin-d) var(--margin-b);
	color: var(--text-color);

	transition: background-color 0.2s ease, box-shadow 0.2s ease;

	& * {
		cursor: pointer;
	}

	&:hover,
	&.active {
		background-color: var(--light-gray);
		box-shadow: 0 0.1em 0.2em rgb(85 85 85 / 20%);
	}
}

.desktop-only {
	display: none;
}

.card {
	border-radius: var(--border-radius);
	padding: var(--margin-b);
	box-shadow: 0 0.1em 0.25em var(--shadow-color);
	transition: 0.2s ease background-color, 0.2s linear transform,
		0.2s ease box-shadow;
	-webkit-user-select: none;
	user-select: none;
	background-color: var(--subtle-gray);

	&:hover {
		background-color: var(--sidebar-color);
	}

	&:active {
		transform: translateY(0.1em);
		box-shadow: 0 0 0.1em var(--shadow-color);
	}

	&--no-hover {
		-webkit-user-select: text;
		user-select: text;

		&:hover {
			background-color: transparent;
		}

		&:active {
			transform: none;
			box-shadow: 0 0.1em 0.25em var(--shadow-color);
		}
	}

	.more_menu {
		margin-left: auto;
	}

	h4 {
		font-size: 1.2em;
	}

	h4 + p {
		margin-top: var(--margin-d);
	}

	&__picture {
		display: block;
		margin-bottom: var(--margin-c);
	}
}

a.card,
li.card > a {
	width: 100%;
	height: 100%;
	display: block;
}

.list {
	> li {
		position: relative;
		padding: var(--margin-d) 0;
		display: flex;
		align-items: center;
	}

	> li:first-of-type {
		padding-top: 0;
	}

	> li:not(:last-of-type)::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -0.5px;
		height: 1px;
		background-color: var(--light-gray);
	}

	&__end {
		margin-left: auto;
		display: flex;
		gap: var(--margin-e);
		align-items: center;
	}

	&__expand {
		background: none;
		width: 100%;
		display: flex;
		justify-content: center;
		padding: var(--margin-e);
		color: var(--medium-gray);
	}

	&--date {
		li {
			display: flex;
			align-items: center;
			gap: var(--margin-d);

			span.date {
				font-size: 0.8em;
				color: var(--medium-gray);
			}
		}
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
	gap: var(--margin-b);

	li {
		display: flex;
		align-items: center;
		gap: var(--margin-d);
	}
}

.more_menu {
	position: relative;

	&__button {
		background: none;
		border-radius: 100vh;
		padding: var(--margin-d);
	}

	&__popup {
		position: absolute;
		top: 110%;
		right: 0;
		background-color: var(--background-color);
		border-radius: var(--margin-d);
		z-index: 10000;
		box-shadow: 0 0.1em 0.25em var(--shadow-color);
		display: none;
		overflow: hidden;

		&--visible {
			display: block;
		}
	}
}

.button-full-width {
	padding: var(--margin-c) var(--margin-b);
	background: none;
	border-radius: 0;
	width: 100%;
	display: block;

	&:hover {
		box-shadow: none;
		background-color: var(--sidebar-color);
	}
}

button {
	border: none;
	font-size: 1em;
	display: flex;
	align-items: center;
	gap: var(--margin-d);
	background: var(--button-color);

	&.primary {
		background: linear-gradient(to bottom right, #3a405a, #685044);
		color: white;
	}
}

.title_buttons {
	display: flex;
	gap: var(--margin-a);
	align-items: center;
	justify-content: space-between;

	&__title {
		font-size: 2em;
	}

	h3 {
		margin-bottom: 0;
	}

	&__buttons {
		display: flex;
		gap: var(--margin-b);
	}
}

.textfeld {
	display: flex;
	border-radius: var(--border-radius);
	overflow: hidden;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: 0 0.5px 2px var(--shadow-color), inset 0 0 0 #00000000;
	transition: 0.2s ease box-shadow;
	color: var(--text-color);
	background: var(--subtle-gray);

	&:focus-within {
		box-shadow: 0 0 0 #00000000, inset 0 0.5px 2px var(--shadow-color);
	}

	input {
		padding: 0 var(--margin-b);
		background: none;
	}

	button {
		border-radius: 0;
	}
}

input:not([type="checkbox"]),
select {
	border: none;
	width: 100%;
	font-size: 1rem;
	color: var(--text-color);

	&:focus {
		outline: none;
	}
}

select > option:first-of-type {
	display: none;
}

.checkbox {
	$checkbox-height: 1.25em;
	$checkbox-width: 2.25em;
	$checkbox-padding: 0.2em;

	height: $checkbox-height;
	width: $checkbox-width;
	position: relative;

	input {
		display: none;

		&:checked + .slider {
			background-color: #33bb33;
			&::after {
				transform: translateX(
					$checkbox-width - 2 * $checkbox-padding - $checkbox-height +
						2 * $checkbox-padding
				);
			}
		}
	}

	.slider {
		position: absolute;
		inset: 0;
		cursor: pointer;
		background-color: var(--medium-gray);
		transition: all 0.2s;
		border-radius: 100vh;
		display: flex;
		padding: $checkbox-padding;
		align-items: center;

		&::after {
			content: "";
			height: $checkbox-height - 2 * $checkbox-padding;
			width: $checkbox-height - 2 * $checkbox-padding;
			background-color: white;
			transition: all 0.2s ease;
			border-radius: 100vh;
		}
	}
}

.alert {
	display: flex;

	position: fixed;
	height: 2em;
	inset: 0;
	top: calc(var(--alert-height) - 2em);
	bottom: unset;
	background: var(--light-gray);
	z-index: 20000;
	align-items: center;
	padding: 0 var(--margin-d);

	transition: 0.5s ease top;

	&--visible {
		--alert-height: 2em;
	}

	&__close {
		margin-left: auto;
		background: var(--dark-gray);
		color: var(--background-color);
		padding: calc(var(--margin-e) / 2);
		line-height: 0;
		width: min-content;

		svg {
			height: 0.8em;
			width: 0.8em;
		}
	}
}

.sidebar {
	display: flex;
	flex-direction: column;
	z-index: 0;
	position: fixed;
	left: 0;
	bottom: 0;
	top: calc(var(--topbar-height) + var(--alert-height));
	transition: 0.5s ease top;

	width: var(--sidebar-width);
	overflow-y: auto;
	overflow-x: hidden;
	padding: var(--margin-a);
	padding-top: var(--margin-b);

	* {
		flex-shrink: 0;
	}

	nav ul {
		display: flex;
		flex-direction: column;
		gap: var(--margin-e);

		a {
			padding: var(--margin-d) var(--margin-c);
			margin: 0 calc(-1 * var(--margin-c));

			display: flex;
			align-items: center;
			gap: var(--margin-d);
			color: var(--text-color);
		}
	}

	footer {
		font-size: 0.7em;
		color: var(--dark-gray);
		margin-top: auto;
	}

	&--mobile {
		main {
			transform: translateX(var(--sidebar-width));
		}

		.loading {
			left: var(--sidebar-width);
			right: calc(-1 * var(--sidebar-width));
		}
	}
}

.topbar {
	z-index: 18090;
	position: fixed;
	inset: 0;
	top: var(--alert-height);
	bottom: unset;
	height: var(--topbar-height);
	padding: var(--margin-d) var(--margin-b);
	background-color: var(--sidebar-color);

	transition: 0.5s ease top;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	justify-content: end;
	gap: var(--margin-c);

	&__menu {
		background: none;
		color: var(--text-color);
	}

	> div,
	&__branding,
	&__menu {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: var(--margin-d);
	}

	&__branding {
		justify-content: center;
		margin: 0 auto;

		h1 {
			font-size: 1.5em;
		}

		img {
			height: 32px;
		}
	}

	.clickable,
	&__menu {
		@media screen and (max-width: 799px) {
			border-radius: 100vh;
			aspect-ratio: 1;
		}
	}

	&__user {
		position: relative;

		&__flyout {
			display: none;
			position: absolute;
			top: 130%;
			right: 0;
			width: 16em;
			background-color: var(--background-color);
			border-radius: var(--border-radius);
			box-shadow: 0 2px 4px var(--shadow-color);
			overflow: hidden;

			&--visible {
				display: block;
			}

			&__info {
				display: flex;
				padding: var(--margin-b);
				padding-bottom: var(--margin-c);
				align-items: center;
				gap: var(--margin-e);
				flex-direction: column;

				p {
					filter: opacity(0.5);
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: 100%;
					text-align: center;
				}
			}

			&__buttons {
				width: 100%;
				text-align: center;
			}
		}
	}
}

main {
	// Animate to var(--sidebar-width) on menu
	//transform: translateX(0);
	transition: 0.5s ease transform;
	position: absolute;
	inset: 0;

	overflow-x: hidden;
	overflow-y: auto;

	margin-top: calc(var(--topbar-height) + var(--alert-height));

	padding: var(--margin-a);
	flex-flow: column;
	flex: 1;
	z-index: 1;

	display: flex;
	flex-direction: column;
	gap: var(--margin-a);

	background-color: var(--background-color);
	border-top-left-radius: var(--border-radius);
	//box-shadow: inset 0 0 0.5em var(--shadow-color);
}

section {
	h3 {
		font-size: 1.25em;
		margin-bottom: var(--margin-b);
	}
}

.page-header {
	padding: var(--margin-a);
	background-color: var(--page-header-color);
	margin: calc(-1 * var(--margin-a));
	margin-bottom: 0;

	&__title-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: var(--margin-d);
	}

	&--nav {
		padding-bottom: var(--margin-e);
	}

	.sec-nav {
		margin: 0 calc(-1 * var(--margin-a));
		padding: var(--margin-c) var(--margin-a);
		margin-top: var(--margin-d);
		overflow: auto;
		white-space: nowrap;

		ul {
			display: flex;
			gap: var(--margin-e);

			a.active {
				background-color: var(--background-color);
				box-shadow: inset 0 1px 1px rgb(85 85 85 / 33%);
				font-weight: bold;
			}

			li:last-of-type a {
				margin-right: var(--margin-a);
			}
		}
	}
}

.page-content {
	z-index: 0;

	&.loading {
		.loading-screen {
			display: flex;
		}
	}
}

.loading-screen {
	position: absolute;
	inset: 0;
	height: calc(100vh - var(--topbar-height) - var(--alert-height) - 6em);
	z-index: 100000;
	background-color: var(--background-color);
	display: none;
	align-items: center;
	justify-content: center;
	transition: inset 0.5s ease;

	img {
		height: 6em;
		animation: loading 0.5s infinite alternate ease;
	}

	@keyframes loading {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(1.1);
		}
	}
}

.cards {
	display: flex;
	gap: var(--margin-b);
	margin: -0.5em calc(-1 * var(--margin-a));
	padding: 0.5em var(--margin-a);
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-padding-left: var(--margin-a);

	li {
		width: 16em;
		flex-shrink: 0;
		scroll-snap-align: start;
	}
}

.poll {
	.arrow {
		line-height: 0;
		padding: var(--margin-d);

		svg {
			height: 0.8em;
			width: 0.8em;
		}

		&--down svg {
			transform: rotate(180deg);
		}

		&--active {
			background-color: var(--button-color-active);
			box-shadow: inset 0 1px 1px var(--shadow-color);
		}
	}

	&__info {
		display: flex;
		align-items: center;
		gap: var(--margin-e);
		margin-bottom: var(--margin-c);

		&:empty {
			display: none;
		}
	}

	&__nomination {
		display: block !important;

		&__content {
			display: flex;
			gap: var(--margin-b);
			align-items: center;
			width: 100%;

			&__votes {
				display: grid;
				grid-template-areas: "up votes" "down votes";
				align-items: center;
				gap: var(--margin-d);

				span {
					grid-area: votes;
					display: flex;
					align-items: center;
					font-weight: bold;
				}

				&--only-up {
					display: flex;
					gap: var(--margin-c);
				}
			}

			&__comments_button {
				background: none;
			}
		}

		&__comments {
			margin-top: var(--margin-c);

			&__comment {
				display: flex;
				gap: var(--margin-d);
				margin-bottom: var(--margin-c);

				&__author {
					font-weight: 600;
				}
			}
		}
	}
}

.polls {
	.abstimmung-block {
		display: block;

		&__oben {
			display: flex;
			width: 100%;
			min-height: 2em;

			.arrow {
				transform: rotate(90deg);
				transition: 0.2s transform ease;
			}

			&--aktiv {
				.arrow {
					transform: rotate(180deg);
				}
			}

			&__links {
				display: flex;
				align-items: center;
				gap: var(--margin-c);
				width: 100%;
				cursor: pointer;
				-webkit-user-select: none;
				user-select: none;

				svg {
					width: 0.75rem;
					flex-shrink: 0;
				}
			}

			&__rechts {
				display: flex;
				align-items: center;

				&__votes {
					margin-left: var(--margin-c);
					width: max-content;
					color: var(--medium-gray);
				}
			}
		}

		&__inhalt {
			margin-top: var(--margin-c);
		}
	}
}

.dashboard__timeline {
	max-width: 100vw;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
	gap: var(--margin-a);

	section {
		max-width: calc(100vw - var(--margin-a) * 2);
	}
}

.chat {
	ul {
		display: flex;
		flex-direction: column;
		gap: var(--margin-c);
		margin-bottom: 3em;
	}

	&__textbox {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: var(--margin-b);
		background-color: var(--background-color);
	}

	&__message {
		background-color: var(--sidebar-color);
		border-radius: var(--border-radius);
		border-top-left-radius: 0;
		padding: var(--margin-c);
		width: -moz-fit-content;
		width: fit-content;
		max-width: 85%;
		display: flex;
		gap: var(--margin-c);
		position: relative;

		&__picture {
			border-radius: 100vh;
			height: 2em;
			width: 2em;
			margin: auto 0;
		}

		&__from {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			flex-wrap: wrap;
			gap: 0 var(--margin-d);
			margin-bottom: var(--margin-e);
			width: 100%;
		}

		&__author {
			font-weight: bold;
		}

		&__date {
			font-size: 0.75em;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: -1em;
			border-style: solid;
			border-width: 0 1em 1em 0;
			border-color: transparent var(--sidebar-color) transparent
				transparent;
		}

		&--you {
			border-top-left-radius: var(--border-radius);
			border-top-right-radius: 0;
			margin-left: auto;
			background-color: var(--light-gray);

			&::after {
				left: unset;
				right: -1em;
				border-width: 1em 1em 0 0;
				border-color: var(--light-gray) transparent transparent
					transparent;
			}
		}
	}
}

.teams {
	display: flex;
	flex-direction: column;
	gap: var(--margin-a);

	.team .title_buttons {
		margin-bottom: var(--margin-c);
	}

	.cards li {
		width: 10em;
	}
}

.settings {
	&__profile {
		display: flex;
		gap: var(--margin-a);
		align-items: center;
		flex-direction: column;
		align-items: flex-start;
		align-items: start;

		.upload- {
			&label {
				margin-top: var(--margin-b);
				display: block;
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: var(--margin-d);
				padding-left: var(--margin-d);
				position: relative;
				overflow: hidden;

				* {
					z-index: 2;
				}

				.upload-progress {
					position: absolute;
					inset: 0;
					right: unset;
					background-color: #67cb67;
					z-index: 0;
				}
			}

			&input {
				display: none;
			}
		}

		&__left {
			width: 8em;

			img {
				width: 8em;
				height: 8em;
			}

			label {
				background-color: var(--button-color);
			}
		}

		&__right {
			width: 100%;

			h3 {
				font-size: 2em;
				font-weight: 600;
				margin-bottom: var(--margin-d);
			}

			p {
				margin-bottom: var(--margin-e);
			}

			&__buttons {
				margin-top: var(--margin-b);
				display: flex;
				gap: var(--margin-a);
			}
		}

		&__password {
			margin-bottom: var(--margin-b);
		}

		&__edit__button {
			display: flex;
			gap: var(--margin-d);
			align-items: center;
			filter: opacity(0.6);
			transition: filter 0.2s ease;
			margin-bottom: var(--margin-c);

			&:hover {
				filter: none;
			}
		}
	}

	&__notifications {
		&__button {
			justify-content: center;
			width: 100%;

			&--activate {
				background-color: rgb(247, 194, 59);
				padding: var(--margin-b);
			}
		}

		&__info {
			display: block;
			margin-top: var(--margin-b);
			margin-bottom: var(--margin-a);
		}

		&__categories {
			li {
				display: flex;
				gap: var(--margin-d);
				align-items: center;
				padding: var(--margin-c) 0;

				.checkbox {
					margin-left: auto;
				}
			}
		}
	}

	&__users {
		&__waiting {
			margin-bottom: var(--margin-a);

			&__accept {
				&:hover {
					background-color: #8eb88e;
				}
			}

			&__delete {
				&:hover {
					background-color: #c18888;
				}
			}
		}

		&__list__role {
			color: var(--medium-gray);

			&--select {
				width: min-content;

				> option:first-of-type {
					display: initial;
				}
			}
		}

		.list li {
			display: flex;
			gap: var(--margin-d);

			img {
				margin: var(--margin-e) 0;
			}
		}
	}

	&__about {
		background: linear-gradient(
			to bottom right,
			rgb(58, 64, 90),
			rgb(104, 80, 68)
		);
		margin: calc(-1 * var(--margin-a));
		color: white;
		padding: calc(2 * var(--margin-a));
		display: flex;
		flex-direction: column;
		gap: var(--margin-a);
		align-items: center;

		img {
			border: 3px solid white;
		}

		&__changelog {
			margin-top: calc(2 * var(--margin-a));

			h2 {
				margin-bottom: var(--margin-b);
			}

			> ul {
				display: flex;
				flex-direction: column;
				gap: var(--margin-b);
			}

			&__entry {
				display: flex;
				gap: 2em;
				position: relative;

				> div {
					width: 100px;
					flex-shrink: 0;
				}

				h3 {
					margin-bottom: var(--margin-e);

					+ p {
						color: var(--dark-gray);
					}
				}

				ul {
					list-style-type: disc;

					&li::before {
						background-color: green;
					}
				}

				&:not(:last-of-type) {
					padding-bottom: calc(var(--margin-b) - 0.5px);

					&::after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 1px;
						background-color: var(--light-gray);
					}
				}
			}
		}
	}
}

.settings__profile__right form,
.zeitung__steckbrief__form {
	gap: var(--margin-c);
	grid-template-columns: 6em 1fr;
	align-items: center;

	.error {
		margin-top: var(--margin-c);
		grid-column: 1 / -1;
		color: rgb(163, 42, 42);
		font-weight: bold;
	}

	label {
		margin-bottom: var(--margin-e);
		display: block;
	}

	input,
	select {
		padding: var(--margin-b);
		margin-bottom: var(--margin-b);
	}

	button {
		grid-column: 1 / 3;
		width: min-content;
		margin-left: auto;
	}
}

.zeitung {
	&__description {
		display: flex;
		flex-direction: column;
		gap: var(--margin-d);
		margin-bottom: var(--margin-a);
	}

	&__botschaften {
		&--users {
			.grid {
				grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
			}

			a.card {
				display: flex;
				gap: var(--margin-c);
				overflow: hidden;
				align-items: center;

				p {
					margin-top: var(--margin-e);
					display: flex;
					align-items: center;
					gap: var(--margin-e);
				}
			}
		}

		&--user {
			&__user {
				display: flex;
				gap: var(--margin-b);
				align-items: center;
				margin-bottom: var(--margin-b);

				h2 {
					margin-bottom: var(--margin-e);
				}
			}

			ul.list li {
				display: flex;
				gap: var(--margin-d);

				img {
					margin: var(--margin-e) 0;
				}
			}

			ul.grid {
				margin-bottom: var(--margin-b);
				grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
			}
		}
	}

	&__freundestexte {
		&__buttons {
			display: flex;
			gap: var(--margin-d);
			margin-bottom: var(--margin-a);
		}

		&__button {
			width: 100%;
			display: flex;
			justify-content: center;

			&--mitmachen {
				color: rgb(249, 222, 201);
				background: linear-gradient(
					to bottom right,
					rgb(58, 64, 90),
					rgb(104, 80, 68)
				);
				font-weight: bold;
			}
		}
	}

	&__umfragen__vorschlaege {
		margin-top: var(--margin-a);

		ul {
			margin-bottom: var(--margin-b);
		}
	}

	&__steckbrief {
		&__edit-button {
			margin-bottom: 1em;
			font-weight: bold;
		}

		&__category {
			display: flex;
			gap: var(--margin-d);

			&__type {
				color: var(--medium-gray);
			}

			&__add {
				margin-top: 2em;

				select {
					background: none;
					color: var(--text-color);

					> option:first-of-type {
						display: initial;
					}
				}

				option {
					color: initial;
				}
			}
		}

		&__other-profile {
			&__category {
				font-weight: bold;
			}

			li {
				display: flex;
				flex-direction: column;
				gap: var(--margin-d);
				margin-bottom: var(--margin-d);
			}
		}

		&__form {
			grid-template-columns: minmax(auto, 16em) minmax(18em, 1fr);

			label {
				font-weight: bold;
			}
		}
	}

	&__ihnn {
		&__question {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: var(--margin-b);
			margin-bottom: var(--margin-a);

			&__progress {
				color: var(--dark-gray);
			}

			&__category {
				font-size: 1.25em;
				font-weight: bold;
				color: var(--accent-color);
				text-transform: uppercase;
				margin-top: var(--margin-e);
			}

			&__question {
				font-size: 2.5em;
				font-weight: bold;
				margin-bottom: var(--margin-b);
			}

			&__buttons {
				display: flex;
				flex-direction: column;
				gap: var(--margin-a);
				justify-content: center;

				button {
					padding: var(--margin-b);
					background: none;
					font-size: 1.25em;
					cursor: pointer;
					transition: all 0.2s ease-in-out;

					&:hover {
						transform: translateY(-2px);
					}
				}

				&__button {
					&--no {
						border: 2px solid #75c049;
						&:active {
							background: #75c049;
							color: white;
						}
					}
					&--yes {
						border: 2px solid #f26630;
						&:active {
							background: #f26630;
							color: white;
						}
					}
				}
			}

			&__skip {
				background: none;
				display: block;
				color: var(--medium-gray);
			}
		}

		&__new {
			margin-bottom: var(--margin-a);

			form {
				margin-top: var(--margin-c);
			}
		}

		&__answered {
			&__category {
				margin-bottom: var(--margin-b);

				&__list {
					flex-direction: column;

					> div {
						display: flex;
						align-items: center;
						align-self: flex-start;
					}
				}

				h4 {
					margin-bottom: var(--margin-d);
				}
			}
		}
	}
}

.financing {
	display: flex;
	flex-direction: column;
	gap: var(--margin-a);

	&__overview {
		&__text {
			display: flex;
			margin-bottom: var(--margin-c);

			h3 {
				font-size: 2rem;
				margin-bottom: 0;
			}

			&__needed {
				margin-left: auto;
				text-align: right;
			}
		}
	}

	&__lists {
		display: grid;
		gap: var(--margin-a);
		grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
	}
}

.small-progress {
	height: 0.5em;
	width: 1.5em;
	flex-shrink: 0;
	border-radius: 100vh;
	background-color: var(--light-gray);
	margin-right: var(--margin-d);
	overflow: hidden;

	> div {
		height: 100%;
		background-color: var(--accent-color);
		transition: width 0.2s ease;
		border-bottom-right-radius: 100vh;
		border-top-right-radius: 100vh;
	}
}

.progress-bar {
	height: 3rem;
	background-color: var(--sidebar-color);
	border-radius: var(--border-radius);
	overflow: hidden;

	&__progress {
		height: 100%;
		background-color: #33bb33;
		transition: width 0.2s ease;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		justify-content: end;
		padding-right: var(--margin-b);
		color: white;
		font-size: 1.25rem;
	}
}

// Login & Co.
.beyond {
	background: linear-gradient(
		to bottom right,
		rgb(58, 64, 90),
		rgb(104, 80, 68)
	);
	background-attachment: fixed;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: min-content;
	min-height: 100%;
	padding: 2em;
	display: flex;
	flex-direction: column;
	color: black;

	footer {
		height: min-content;
		margin: 2em auto 0 auto;
		color: white;

		a {
			opacity: 0.8;
			transition: opacity 0.2s ease;

			&:hover {
				opacity: 1;
			}
		}
	}

	.privacy {
		width: 100%;
		text-align: left;
		line-height: 1.5;

		address {
			font-style: normal;
		}

		a {
			text-decoration: underline var(--text-light) 1.5px;
		}

		h3,
		h4 {
			margin-top: 1em;
		}
	}

	&__box {
		background-color: rgba(#eee, 0.8);
		border-radius: 1em;
		padding: 2em;
		width: 28em;
		display: flex;
		flex-direction: column;
		gap: 1em;
		text-align: center;
		margin: auto;

		@media only screen and (max-width: 500px) {
			width: 100%;
		}

		&.datenschutz {
			width: 100%;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 1em;
		}

		&__logo {
			display: flex;
			align-items: center;
			gap: 1em;
			justify-content: center;
		}

		h2 {
			margin-top: 1rem;
		}

		a {
			margin-bottom: 1rem;
		}

		input {
			border-radius: var(--border-radius);
			padding: var(--margin-b);
			color: black !important;
		}

		&__button {
			padding: 1em 2em;
			font-size: 1em;
			border-radius: 1em;
			border: none;
			background-color: #555;
			color: white;
			width: min-content;
			align-self: center;

			&:hover {
				color: rgb(249, 222, 201);
				background-color: rgb(58, 64, 90);
			}
		}

		&__header {
			display: flex;
			align-items: center;
			gap: 1em;

			.beyond__box__logo {
				margin: 0 auto;
			}
		}

		&__link {
			border-radius: 1em;
			border: 2px solid var(--text);
			width: min-content;
			padding: 0.5em 1em;
			text-decoration: none !important;
			margin-bottom: 0 !important;
		}

		&__error {
			border-radius: 1em;
			border-color: rgb(163, 42, 42);
			border-style: solid;
			background-color: rgb(245, 150, 150);
			display: grid;
			place-content: center;
			padding: 0 1em;

			height: 0;
			border-width: 0;
			margin: -0.5em 0;
			color: #0000;

			transition: height 0.5s ease-in-out, border-width 0.5s ease-in-out,
				margin 0.5s ease-in-out, color 0.2s 0.5s ease-in-out;

			&--visible {
				height: 4.5em !important;
				border-width: 1px !important;
				color: #000;
				margin: 0;
			}
		}
	}
}

.disclaimer {
	font-style: italic;
	margin-bottom: var(--margin-d);
}

.progress {
	--progress-ring-width: 160px;
	--stroke-dasharray: 440;
	--stroke-dashoffset: 80;

	width: var(--progress-ring-width);
	height: var(--progress-ring-width);

	position: relative;

	svg {
		transform: rotate(
			calc(
				90deg + (var(--stroke-dashoffset) / var(--stroke-dasharray)) *
					180deg
			)
		);
	}

	svg,
	circle {
		position: absolute;
		inset: 0;

		width: var(--progress-ring-width);
		height: var(--progress-ring-width);
	}

	circle {
		position: absolute;
		fill: none;
		stroke-width: 9.9;
		transform: translate(10px, 10px);
		stroke: var(--sidebar-color);
		stroke-dasharray: var(--stroke-dasharray);
		stroke-dashoffset: var(--stroke-dashoffset);

		stroke-linecap: round;
	}

	circle:nth-child(2) {
		stroke: #33bb33;
		stroke-width: 10;
		animation: percent 1.5s ease;
		transition: stroke-dashoffset 0.2s ease;
	}

	@keyframes percent {
		0% {
			stroke-dashoffset: var(--stroke-dasharray);
		}
	}

	.number {
		position: absolute;
		inset: 0;
		display: grid;
		place-items: center;
	}

	h2 {
		font-size: 2.5rem;
	}

	span {
		font-size: 1.5rem;
	}
}
