@media screen and (min-width: 800px) {
	:root {
		--sidebar-width: 16em;
	}

	.desktop-only {
		display: initial;
	}

	.mobile-only {
		display: none;
	}

	.topbar {
		padding-left: 0;

		&__branding {
			width: var(--sidebar-width);
			margin-left: 0;
		}
	}

	.sidebar--mobile main {
		transform: none;
	}

	main {
		margin-left: var(--sidebar-width);

		&::before,
		&::after {
			left: var(--sidebar-width);
		}
	}

	.chat {
		&__textbox {
			left: var(--sidebar-width);
			right: 0;
			width: auto;
		}

		&__message {
			max-width: 75%;
		}
	}

	.poll {
		&__nomination {
			&__content__votes {
				display: flex;
				min-width: 6em;
				justify-content: space-between;

				&--only-up {
					min-width: 4em;

					span {
						width: 100%;
						text-align: center;
						display: block;
					}
				}
			}

			&__comments {
				margin-left: calc(6em + var(--margin-b));
			}
		}
	}

	.settings__profile {
		flex-direction: row;
	}

	.zeitung__freundestexte__button {
		max-width: 12rem;
	}

	.zeitung__ihnn__question__buttons {
		flex-direction: row;
	}

	.zeitung__ihnn__answered__category__list {
		flex-direction: row;

		> div {
			align-self: center;
		}
	}

	.zeitung__steckbrief__other-profile li {
		flex-direction: row;
		align-items: baseline;
	}

	.settings__profile__right form,
	.zeitung__steckbrief__form {
		display: grid;

		input,
		select,
		label {
			margin-bottom: 0;
		}
	}

	.settings__profile__right__password {
		grid-template-columns: 8em 1fr !important;
	}

	.settings__about {
		flex-direction: row;
	}

	.two-selections {
		display: flex;
		flex-direction: column;
		gap: var(--margin-c);
	}
}

@media screen and (min-width: 1200px) {
	.two-selections {
		flex-direction: row;
	}
}
